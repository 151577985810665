<template>
    <div class="container">
        <div class="page-container">
            <el-form :inline="true" class="fr-s-s">

                <el-form-item>
                    <el-popconfirm title="您确定要清空日志么？" icon-color="red" v-if="permission.batchRemove"
                        @confirm="onClearHandle()">
                        <el-button slot="reference" type="primary" plain>清空日志</el-button>
                    </el-popconfirm>
                </el-form-item>
                <!-- <el-form-item v-if="permission.batchRemove">
                    <el-popconfirm title="您确定要批量删除选中日志吗？" @confirm="selectTotalRemove()">
                        <el-button size="small" plain slot="reference" type="primary">批量删除</el-button>
                    </el-popconfirm>
                </el-form-item> -->
            </el-form>
            <el-table ref="table" :data="tableData" border stripe :row-key="'id'" v-loading="showLoading"
                :height="tableHeight" @selection-change="handleSelectionChange">
                <!-- <el-table-column type="selection" width="55" align="center">
                </el-table-column> -->
                <el-table-column prop="id" label="编号" align="center" width="60">
                    <template slot-scope="scope"> {{ scope.$index + 1 }}</template>
                </el-table-column>
                <el-table-column prop="username" label="操作人" width="150" align="center"></el-table-column>
                <el-table-column prop="operation" label="简介" min-width="250" align="center"></el-table-column>
                <el-table-column prop="ip" label="ip地址" width="150" align="center"></el-table-column>
                <el-table-column prop="gmtCreate" label="操作时间" width="150" align="center"></el-table-column>

                <el-table-column label="操作" width="110px" align="center">
                    <template slot-scope="scope">
                        <!-- <el-tooltip class="item" effect="dark" content="编辑" placement="top" v-if="permission.relationshipUpdate">
                            <el-button class="success" type="text" icon="el-icon-edit-outline"
                                @click="clickEditInfo(scope.row)"></el-button>
                        </el-tooltip> -->
                        <el-tooltip class="item" effect="dark" content="删除" placement="top"
                            v-if="permission.listvRemove">
                            <el-popconfirm title="您确定要删除么？" icon-color="red"
                                @confirm="deleteAdmin(scope.row, scope.$index)">
                                <el-button slot="reference" icon="el-icon-delete" type="text" class="danger">
                                </el-button>
                            </el-popconfirm>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination ref="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="query.offset" :page-sizes="[10, 30, 50, 100]" :page-size="query.limit"
                layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
            </el-pagination>
        </div>
        <!-- 查看关系详情 -->
        <show-content v-model="dialogSeeContent" :title="rowInfo.title" :content="rowInfo.content"></show-content>
    </div>
</template>

<script>
import {
    getList
} from '@/tools/getList.js'
import systemSetManager from "@/request/systemSetManager.js";
import ShowContent from "@/components/common/subgroup/ShowContent.vue"
export default {
    mixins: [getList],
    components: {
        ShowContent,
    },
    data() {
        return {
            // requst: {
            //     module: systemSetManager,
            //     url: 'listvList',
            // },
            dialogSeeContent: false,
            rowInfo: {},
            // query: {
            //     // key_words: '',    //搜索词
            // },
            addOrEditDialog: false,
            addOrEditBtnLoading: false,
            editForm: {
                id: 0,
                title: "",
                orderWeight: 9,
            },
            addRules: {
                title: [
                    { required: true, message: "请输入关系名称", trigger: "blur" },
                ],
                orderWeight: [
                    { required: true, message: "请输入权重", trigger: "blur" },
                ],
            },
            multipleSelection: [],
        };
    },
    created() {
        this.getLogList();
        this.handlePermission();
    },
    methods: {
        async getLogList() {
            try {
                this.showLoading = true;
                this.tableData = [];
                let data = await systemSetManager.listvList(this.query);
                if (data.rows) {
                    this.tableData = data.rows;
                }
                this.pageTotal = data.total;
                this.showLoading = false;
            } catch (error) {
                this.showLoading = false;
                // console.log();
            }
        },
        //更改每页显示条数
        async handleSizeChange(val) {
            this.query.limit = val;
            this.query.offset = 1;
            this.getLogList();
        },
        //设置当前页
        async handleCurrentChange(val) {
            this.query.offset = val;
            this.getLogList();
        },
        async onClearHandle() {
            try {
                let data = await systemSetManager.batchRemove();
                if (data.code == 200) {
                    this.$message.success(data.message);
                    this.getLogList();
                    this.resetAddOrEditData();
                }
            } catch (error) {
                console.log(error);
            }
        },

        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        async selectTotalRemove() {
            if (this.multipleSelection.length == 0) {
                this.$message.error("请选择要删除的日志")
                return;
            }
            var array = [];
            this.multipleSelection.forEach(obj => {
                array.push(obj.id)
            })
            try {
                let data = await systemSetManager.batchRemovev({ ids: array.join(",") })
                if (data.code == 200) {
                    this.$message.success(data.message);
                    this.$emit("success")
                    this.refreshData()
                }
            } catch (error) {
                //
                console.log(error);
            }
        },
        hoverContent(row) {
            this.rowInfo = row;
            this.dialogSeeContent = true;
        },

        // 点击添加
        onAdd() {
            this.addOrEditDialog = true
        },

        // 点击添加---取消
        clickAddOrEditCancel() {
            this.resetAddOrEditData()
        },
        // 点击添加---确定
        clickAddOrEditConfirm() {
            this.$refs.editForm.validate(async (valid) => {
                // if (valid) {
                //     console.log(this.editForm)
                //     this.addOrEditBtnLoading = true
                //     this.postAddRegionInfo()
                // }
                if (valid) {
                    try {
                        this.addOrEditBtnLoading = true
                        let dataValue = JSON.parse(JSON.stringify(this.editForm))
                        let data = await this.requst.module[this.editForm.id > 0 ? this.requst.updateUrl : this.requst.addUrl](dataValue)
                        if (data.code == 200) {
                            this.$message({
                                message: data.message,
                                type: 'success'
                            });
                            this.$emit('success')
                            this.refreshData()
                            this.resetAddOrEditData()
                        }
                    } catch (error) {
                        console.log(error)
                    } finally {
                        this.addOrEditBtnLoading = false
                    }

                }
            })
        },

        // 清除添加数据
        resetAddOrEditData() {
            this.addOrEditDialog = false
            this.editForm = {
                id: 0,
                title: "",
            }
        },
        // 点击编辑信息
        async clickEditInfo(row) {
            console.log("row", row);
            this.editForm = {
                id: row.id,
                title: row.title,
            }
            this.addOrEditDialog = true
        },

        // 点击删除
        async deleteAdmin(row, index) {
            try {
                let { code, message } = await systemSetManager.listvRemove({ id: row.id });
                if (code === 200) {
                    // this.$message.success(message);
                    this.$message({
                        message: message,
                        type: 'success'
                    });
                    // this.$message({ message: "删除成功", type: "success" });
                    this.delRefresh(index);
                }
            } catch (error) {
                console.log(error)
            }
        },
    },
};
</script>

<style scoped>

</style>

